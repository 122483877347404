<template>
	<div class="product_box" v-if="show">
		<van-tabs v-model="active" scrollspy sticky color="#2594EF" title-active-color="#2594EF">
			<van-tab title="保障责任">
				<div class="pro_title">{{ proName }}-{{ comboName }}</div>
				<div class="content_box">
					<div class="title"><div class="ml_10">保障责任</div></div>
					<div class="flex_between_box">
						<div class="table_title">保障内容</div>
						<div class="table_title">保障额度（元）</div>
					</div>
					<div :class="['flex_between_box', { bg_color: index % 2 == 1 }]" v-for="(item, index) in protectList" :key="index">
						<div class="table_content">{{ item.contentName }}</div>
						<div class="table_content text_center">{{ item.price }}</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="特别约定">
				<div class="content_box">
					<div class="title"><div class="ml_10">特别约定</div></div>
					<div v-html="hint"></div>
				</div>
			</van-tab>
			<van-tab title="免责条款">
				<div class="content_box">
					<div class="title"><div class="ml_10">免责条款</div></div>
					<div v-html="preventDuty"></div>
				</div>
			</van-tab>
			<van-tab title="产品解读">
				<div class="content_box">
					<div class="title"><div class="ml_10">产品解读</div></div>
					<div v-html="productCase" class="product_case"></div>
				</div>
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import { http_getProductGuarantee,http_saveCrmSelfPoint } from '@/request/crm';
export default {
	components: {
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
	},
	data() {
		return {
			active: '保障责任',
			user: '',
			protectList: [], //保障责任
			hint: '', //投保须知,
			productCase: '', //产品解读
			preventDuty: '', //免责条款,
			proName: '',
			comboName: '',
			show:false
		};
	},
	mounted() {
		const { id } = this.$route.query;
		if(!id) return
		this.getProduct(id);
	},
	methods: {
		// 获取保障责任
		async getProduct(id) {
			const res = await http_getProductGuarantee(id);
			this.show=true
			const protectList = res.productComboGuarantee2Vo
			this.comboName = res.comboName;
			this.protectList = protectList.contentList;
			this.hint = res.insProductInformation2Vo.hint;
			this.preventDuty = res.insProductInformation2Vo.preventDuty;
			this.productCase = res.insProductInformation2Vo.productCase;
			this.proName = res.proName;
			// 埋点
			this.savePoint(id)
		},
		// 新增埋点
		async savePoint(id){
             await http_saveCrmSelfPoint({quotationId:id,type:0,pointUrl:window.location.href})
		}
	},
};
</script>

<style lang="scss" scoped>
.product_box {
	font-size: 0.24rem;
}
.ml_10 {
	margin-left: 0.1rem;
}
.content_box {
	padding: 0.2rem;
	word-break: break-all;
}
.flex_between_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title {
	font-size: 0.32rem;
	font-weight: 600;
	margin-bottom: 0.2rem;
	color: $b_color_main;
	border-left: solid 0.1rem $b_color_main;
}
.table_title {
	flex: 1;
	text-align: center;
	padding: 0.14rem;
	background-color: $b_border_color;
}
.text_center {
	text-align: center;
}
.bg_color {
	background-color: $background_color;
}
.table_content {
	padding: 0.12rem 0.1rem;
	flex: 1;
	height: 100%;
}
.pro_title {
	font-size: 0.26rem;
	background-color: $b_border_color;
	padding: 0.16rem;
	margin-bottom: 0.2rem;
	font-weight: 600;
}
::v-deep .product_case img{
    width: 100%;
}
</style>
