import { get, post } from './http';
//获取支付链接
export function http_getPayUrl(id) {
	return get(`/insurance/api/crmSelfQuotation/getPayUrl/${id}`);
}
//获取产品计划保障信息
export function http_getProductGuarantee(id) {
	return get(`/insurance/api/crmSelfQuotation/getProductGuarantee/${id}`);
}
//新增埋点
export function http_saveCrmSelfPoint(data) {
	return post(`/insurance/api/crmSelfQuotation/saveCrmSelfPoint`,data);
}